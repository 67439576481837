.breadcrumbs {
  margin-top: 24px;
  margin-bottom: 24px
}

.breadcrumbs__item {
  display: inline-block;
  font-size: 1rem
}

.breadcrumbs__item:last-child:after {
  display: none
}

.breadcrumbs__item a:hover {
  color: #941b20
}

.breadcrumbs__item a:focus {
  color: #941b20
}

.breadcrumbs__item i {
  font-size: 1rem
}

.breadcrumbs__item:after {
  content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/></svg>');
  display: inline-block;
  position: relative;
  top: -1px;
  font-size: 9px;
  margin: 0 6px;
  color: #941b20
}

.breadcrumbs__url {
  color: #121212
}